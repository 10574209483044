@keyframes SpinnerChildAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.Spinner {
  display: inline-block;
  position: relative;
  width: 5em;
  height: 5em;
  flex-shrink: 0;
}

.Spinner div {
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #007030;
  animation: SpinnerChildAnimation 1.2s linear infinite;
}

.Spinner div:nth-child(1) {
  top: 0.5em;
  left: 0.5em;
  animation-delay: 0s;
}

.Spinner div:nth-child(2) {
  top: 0.5em;
  left: 2em;
  animation-delay: -0.4s;
}

.Spinner div:nth-child(3) {
  top: 0.5em;
  left: 3.5em;
  animation-delay: -0.8s;
}

.Spinner div:nth-child(4) {
  top: 2em;
  left: 0.5em;
  animation-delay: -0.4s;
}

.Spinner div:nth-child(5) {
  top: 2em;
  left: 2em;
  animation-delay: -0.8s;
}

.Spinner div:nth-child(6) {
  top: 2em;
  left: 3.5em;
  animation-delay: -1.2s;
}

.Spinner div:nth-child(7) {
  top: 3.5em;
  left: 0.5em;
  animation-delay: -0.8s;
}

.Spinner div:nth-child(8) {
  top: 3.5em;
  left: 2em;
  animation-delay: -1.2s;
}

.Spinner div:nth-child(9) {
  top: 3.5em;
  left: 3.5em;
  animation-delay: -1.6s;
}
