.flyout-triangle-left {
  left: 0;
  top: 50%;
  transform: translate(-75%, -50%) rotate(-90deg);
}

.flyout-triangle-left-top {
  left: 0;
  top: 1rem;
  transform: translate(-75%, 0) rotate(-90deg);
}

.flyout-triangle-left-bottom {
  left: 0;
  bottom: 1rem;
  transform: translate(-75%, 0) rotate(-90deg);
}

.flyout-triangle-top {
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}

.flyout-triangle-top-right {
  right: 1rem;
  top: 0;
  transform: translate(0, -100%);
}

.flyout-triangle-top-left {
  left: 1rem;
  top: 0;
  transform: translate(0, -100%);
}

.flyout-triangle-right {
  right: 0;
  top: 50%;
  transform: translate(75%, -50%) rotate(90deg);
}

.flyout-triangle-right-top {
  right: 0;
  top: 1rem;
  transform: translate(75%, 0) rotate(90deg);
}

.flyout-triangle-right-bottom {
  right: 0;
  bottom: 1rem;
  transform: translate(75%, 0) rotate(90deg);
}

.flyout-triangle-bottom {
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%) rotate(180deg);
}

.flyout-triangle-bottom-left {
  left: 1rem;
  bottom: 0;
  transform: translate(0, 100%) rotate(180deg);
}

.flyout-triangle-bottom-right {
  right: 1rem;
  bottom: 0;
  transform: translate(0, 100%) rotate(180deg);
}
